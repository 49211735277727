<template>
	<v-sheet class="main-detail recurring-detail">
		<div class="">
			<v-layout class="align-center light-blue-bg px-4 py-2 flex-wrap">
				<v-flex md12>
					<div class="d-flex">
						<v-menu offset-y right bottom content-class="elevation-3" rounded="0">
							<template v-slot:activator="{ on, attrs }">
								<p class="sub-heading m-0" v-on="on" v-bind="attrs">
									All Recurring
									<v-icon>mdi-menu-down</v-icon>
								</p>
							</template>
							<v-list>
								<v-list-item
									v-for="(status, index) in recurringStatus"
									:key="index"
									v-on:click="statusFilter(status.value)"
								>
									<v-list-item-title class="d-flex align-items-center">
										{{ status.text }} <span class="circle" :class="status.status_color"></span>
										<v-spacer></v-spacer>
										<div class="cm-budge white--text" :class="status.status_color">
											{{ status.status_count }}
										</div>
									</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>

						<!-- <div class="d-flex justify-content-end pr-1 ml-4">
							<div class="mr-2 fw-600 my-auto">Filter By</div>
							<div class="filterTagSelect">
								<v-autocomplete
									v-model="filter_tag"
									:items="tagItems"
									:menu-props="{ bottom: true, offsetY: true }"
									prepend-inner-icon="mdi-tag"
									outlined
									hide-details
									class="filterSelect"
									content-class="filterSelectContent"
								>
								</v-autocomplete>
							</div>
							<div class="filterTagSelect ml-3">
								<v-autocomplete
									v-model="filter_user"
									:items="userItems"
									:menu-props="{ bottom: true, offsetY: true }"
									prepend-inner-icon="mdi-filter"
									outlined
									hide-details
									class="filterSelect"
									content-class="filterSelectContent"
								>
								</v-autocomplete>
							</div>
							<div class="filterTagSelect search-field ml-3">
								<v-text-field
									v-model="filter_customer"
									outlined
									placeholder="Search..."
									hide-details
									class="filterSelect"
								>
								</v-text-field>
							</div>
						</div> -->

						<template v-if="singleRecurring && singleRecurring.renewed == 1">
							<v-chip class="px-2 mr-2" label color="cyan white--text" x-small>Renewed</v-chip>
						</template>
						<template v-if="singleRecurring && singleRecurring.cancelled == 1">
							<v-chip class="px-2" label color="red white--text" x-small>Cancelled</v-chip>
						</template>
						<v-spacer></v-spacer>
						<!-- <v-menu offset-y left bottom content-class="elevation-3" rounded="0">
							<template v-slot:activator="{ on, attrs }">
								<div v-on="on" v-bind="attrs" class="mr-2">
									<v-chip link color="green white--text" class="d-block rounded pr-1" label>
										Paid
										<v-icon class="ml-2">mdi-menu-down</v-icon>
									</v-chip>
								</div>
							</template>
							<v-list v-for="(i, index) in 5" :key="index" class="py-0">
								<v-list-item class="px-0 d-block py-1 px-2">
									<v-chip link :color="`${status[index].color} white--text`" class="d-block" label>{{
										status[index].name
									}}</v-chip>
								</v-list-item>
							</v-list>
						</v-menu> -->
						<v-btn
							class="white--text mr-2"
							depressed
							color="blue darken-4"
							tile
							v-on:click="routeToUpdate(recurringId)"
						>
							Edit
						</v-btn>
						<v-btn
							class="white--text mr-2"
							depressed
							color="blue darken-4"
							tile
							v-on:click="updateStatus('renewed')"
						>
							Renew
						</v-btn>
						<v-btn
							class="white--text mr-2"
							depressed
							color="red darken-4"
							tile
							v-on:click="updateStatus('cancelled')"
						>
							Cancel
						</v-btn>
						<v-btn depressed tile class="my-auto" v-on:click="goBack()">
							<v-icon class="mr-1">mdi-keyboard-backspace</v-icon>
							back
						</v-btn>
					</div>
				</v-flex>
			</v-layout>
			<v-layout class="section-main-content">
				<div class="listing-sidebar border-right">
					<div class="side-listing listing-sidebar-list">
						<div class="listing-innerlist">
							<div
								class="list-items"
								v-for="(data, index) in statusData"
								:key="index"
								:class="data.id == recurringId ? 'active' : ''"
								v-on:click="tabDetails(data.id)"
							>
								<div class="ellipsis pr-6 mb-1">
									<v-icon size="20" left>mdi-account</v-icon>
									<span class="fw-500 primary--text" v-if="data && data.contact_person">{{
										data.contact_person
									}}</span>
								</div>
								<div class="ellipsis pr-6 mb-1">
									<v-icon size="20" left>mdi-domain</v-icon>
									<span class="fw-500 primary--text" v-if="data && data.company_name">{{
										data.company_name
									}}</span>
								</div>
								<div class="d-flex justify-content-between">
									<div class="ellipsis pr-6 mb-1">
										<v-icon size="20" left>mdi-phone</v-icon>
										<span class="fw-500 primary--text" v-if="data && data.primary_number">{{
											data.primary_number
										}}</span>
									</div>
								</div>
								<div class="d-flex">
									<div class="ellipsis pr-6 mb-1">
										<v-icon size="20" left>mdi-email</v-icon>
										<span class="fw-500 primary--text" v-if="data && data.primary_email">{{
											data.primary_email
										}}</span>
									</div>
									<v-spacer></v-spacer>
								</div>
								<div class="d-flex">
									<div class="ellipsis pr-6 mb-1">
										<v-icon size="20" left>mdi-web</v-icon>
										<span class="fw-500 primary--text" v-if="data && data.web_url">{{ data.web_url }}</span>
									</div>
									<v-spacer></v-spacer>
								</div>
								<!-- <div class="d-flex justify-content-between">
									<v-tooltip top content-class="custom-top-tooltip">
										<template v-slot:activator="{ on, attr }">
											<div class="ellipsis mb-1" v-on="on" v-bind="attr">
												<v-icon size="20" left color="primary">mdi-web</v-icon>
												<span class="fw-500 primary--text">{{ data.web_url }}</span>
											</div>
										</template>
										<span>Contract Start</span>
									</v-tooltip>
									<v-tooltip top content-class="custom-top-tooltip">
										<template v-slot:activator="{ on, attr }">
											<div class="ellipsis mb-1" v-on="on" v-bind="attr">
												<v-icon size="20" left color="red">mdi-calendar</v-icon>
												<span class="fw-500 red--text">{{ data.end_date }}</span>
											</div>
										</template>
										<span>Contract End</span>
									</v-tooltip>
								</div> -->
							</div>
						</div>
					</div>
					<!-- customer details -->

					<!-- customer details end -->
				</div>
				<div class="details-body grey lighten-4">
					<div class="p-sticky" style="z-index: 1">
						<v-layout
							class="pt-2 pb-0 light-blue-bg flex-wrap"
							style="position: sticky; top: 0; z-index: 2"
						>
							<v-flex md12 class="px-4">
								<div class="d-flex">
									<v-chip
										class="mr-2 text-capitalize"
										color="cyan white--text"
										x-small
										v-if="this.singleRecurring && this.singleRecurring.barcode"
										>{{ this.singleRecurring.barcode }}
									</v-chip>
									<p class="my-auto font-level-3-bold">
										<span
											class="fw-600 mr-2 text-capitalize"
											v-if="singleRecurring && singleRecurring.company_name"
											>{{ singleRecurring.company_name }}</span
										>
									</p>
									<v-spacer></v-spacer>
								</div>
							</v-flex>
							<v-flex md12 class="d-flex flex-wrap px-4">
								<div class="w-25">
									<div class="mt-2">
										<span class="fw-600">Company : </span>
										<span class="fw-500" v-if="singleRecurring && singleRecurring.company_name">
											{{ singleRecurring.company_name }}</span
										>
										<em class="text-muted" v-else> no company</em>
									</div>
									<div class="mt-2 d-flex">
										<span class="fw-600 mr-2">Contact Person </span>
										<span class="fw-500" v-if="singleRecurring && singleRecurring.contact_person">
											{{ singleRecurring.contact_person }}</span
										>
										<em class="text-muted" v-else> no contact person</em>
									</div>
								</div>
								<div class="w-25">
									<div class="mt-2">
										<span class="fw-600">Phone : </span>
										<span class="fw-500">
											<span class="fw-500" v-if="singleRecurring && singleRecurring.primary_number">{{
												singleRecurring.primary_number
											}}</span>
											<em class="text-muted" v-else> no phone</em>
										</span>
									</div>
									<div class="mt-2">
										<span class="fw-600"> Email : </span>
										<span class="fw-500">
											<span class="fw-500" v-if="singleRecurring && singleRecurring.primary_email">{{
												singleRecurring.primary_email
											}}</span>
											<em class="text-muted" v-else> no email</em>
										</span>
									</div>
								</div>
								<div class="w-25">
									<div class="mt-2">
										<span class="fw-600">Designation : </span>
										<span>
											<v-chip
												class=""
												label
												color="green white--text"
												x-small
												v-if="singleRecurring && singleRecurring.designation"
											>
												{{ singleRecurring.designation }}
											</v-chip>
											<em class="text-muted" v-else> no designation</em>
										</span>
									</div>
									<div class="mt-2">
										<span class="fw-600 mr-2">Unit Number : </span>
										<span v-if="singleRecurring && singleRecurring.unit_number">
											{{ singleRecurring.unit_number }}</span
										>
										<em class="text-muted" v-else> no unit number</em>
									</div>
								</div>
								<div class="w-25">
									<div class="mt-2">
										<span class="fw-600">kws : </span>
										<span>
											<v-chip
												class="px-2"
												label
												color="orange white--text"
												x-small
												v-if="singleRecurring && singleRecurring.kws"
											>
												{{ singleRecurring.kws }}
											</v-chip>
											<em class="text-muted" v-else> no kws</em>
										</span>
									</div>
									<div class="mt-2">
										<span class="fw-600 mr-2">Payment Terms : </span>
										<template v-if="singleRecurring && singleRecurring.payment_terms == 'yearly'">
											<v-chip class="px-2" label color="cyan white--text" x-small>Yearly</v-chip>
										</template>
										<template v-else-if="singleRecurring && singleRecurring.payment_terms == 'half_yearly'">
											<v-chip class="px-2" label color="cyan white--text" x-small>Half Yearly</v-chip>
										</template>
										<template v-else-if="singleRecurring && singleRecurring.payment_terms == 'monthly'">
											<v-chip class="px-2" label color="cyan white--text" x-small>Monthly</v-chip>
										</template>
										<template v-else-if="singleRecurring && singleRecurring.payment_terms == 'quarterly'">
											<v-chip class="px-2" label color="cyan white--text" x-small>Quarterly</v-chip>
										</template>
									</div>
								</div>
							</v-flex>
							<v-flex md12 class="px-4 mt-2 grey lighten-4">
								<v-tabs
									v-model="dataTab"
									background-color="transparent"
									color="blue"
									class="custom-tab-transparent border-bottom w-100"
									active-class="blue darken-4 text-white"
									hide-slider
								>
									<v-tab href="#overview"><v-icon small left>mdi-information-outline</v-icon>Overview</v-tab>
									<v-tab href="#invoice"><v-icon small left>mdi-information-outline</v-icon>Invoice</v-tab>
									<v-tab href="#ranking"><v-icon small left>mdi-information-outline</v-icon>KPI</v-tab>
									<v-tab href="#notes"><v-icon small left>mdi-note</v-icon>Notes</v-tab>
									<v-tab href="#file"><v-icon small left>mdi-file-document-multiple</v-icon>Files</v-tab>
								</v-tabs>
							</v-flex>
						</v-layout>

						<div class="pt-2">
							<v-tabs-items v-model="dataTab" class="transparent">
								<v-tab-item value="overview">
									<div class="flaot-wrapper">
										<v-col cols="12" lg="6">
											<div class="detail-card bg-white">
												<div class="card--header d-flex align-items-center pa-2 border-bottom">
													<div class="font-level-3-bold">Address Details</div>
													<v-spacer></v-spacer>
												</div>
												<div class="card--body d-flex align-items-center pa-2">
													<table class="w-100">
														<!-- <tr>
															<td class="text-muted fw-500 px-3 py-2">Unit No</td>
															<td class="fw-600 px-3 py-1" v-if="singleRecurring && singleRecurring.unit_number" >{{singleRecurring.unit_number}}</td>
															<em v-else class="text-muted px-3 py-1">no unit no</em>
														</tr> -->
														<tr>
															<td class="text-muted fw-500 px-3 py-2">Address 1</td>
															<td
																class="fw-600 px-3 py-1"
																v-if="singleRecurring && singleRecurring.address_line_1"
															>
																{{ singleRecurring.address_line_1 }}
															</td>
															<em v-else class="text-muted px-3 py-1">no address 1</em>
														</tr>
														<tr>
															<td class="text-muted fw-500 px-3 py-2">Address 2</td>
															<td
																class="fw-600 px-3 py-1"
																v-if="singleRecurring && singleRecurring.address_line_2"
															>
																{{ singleRecurring.address_line_2 }}
															</td>
															<em v-else class="text-muted px-3 py-1">no address 2</em>
														</tr>
														<tr>
															<td class="text-muted fw-500 px-3 py-2">Postal Code</td>
															<td class="fw-600 px-3 py-1" v-if="singleRecurring && singleRecurring.postal_code">
																{{ singleRecurring.postal_code }}
															</td>
															<em v-else class="text-muted px-3 py-1">no postal code</em>
														</tr>
														<tr>
															<td class="text-muted fw-500 px-3 py-2">Country</td>
															<td class="fw-600 px-3 py-1" v-if="singleRecurring && singleRecurring.country">
																{{ singleRecurring.country }}
															</td>
															<em v-else class="text-muted px-3 py-1">no country</em>
														</tr>
														<tr>
															<td class="text-muted fw-500 px-3 py-2">Currency</td>
															<td class="fw-600 px-3 py-1" v-if="singleRecurring && singleRecurring.currency">
																{{ singleRecurring.currency }}
															</td>
															<em v-else class="text-muted px-3 py-1">no currency</em>
														</tr>
													</table>
												</div>
											</div>
										</v-col>
										<v-col md="12" lg="6">
											<div class="detail-card bg-white">
												<div class="card--header d-flex align-items-center pa-2 border-bottom">
													<div class="font-level-3-bold">Project Details</div>
													<v-spacer></v-spacer>
												</div>
												<div class="card--body d-flex align-items-center pa-2">
													<table class="w-100">
														<tr>
															<td class="text-muted fw-500 px-3 py-2">Project Type</td>
															<td class="fw-600 px-3 py-1 d-flex" style="display: inline">
																<div v-for="(project, index) in project_types" :key="index">
																	<v-chip
																		color="cyan white--text"
																		label
																		class="mr-2 mb-1 text-uppercase"
																		v-if="project"
																		>{{ project.value }}</v-chip
																	>
																</div>
																<!-- <v-chip color="cyan white--text" label class="mr-2 mb-1">Web</v-chip>
																<v-chip color="cyan white--text" label class="mb-1">domain</v-chip> -->
															</td>
														</tr>
														<!-- <tr>
															<td class="text-muted fw-500 px-3 py-2">KWS</td>
															<td class="fw-600 px-3 py-1" v-if="(singleRecurring && singleRecurring.kws)">{{singleRecurring.kws}}</td>
															<em v-else class="text-muted px-3 py-1">no kws</em>
														</tr> -->
														<tr>
															<td class="text-muted fw-500 px-3 py-2">Web URL (Target)</td>
															<td class="fw-600 px-3 py-1" v-if="singleRecurring && singleRecurring.web_url">
																{{ singleRecurring.web_url }}
															</td>
															<em v-else class="text-muted px-3 py-1">no web url</em>
														</tr>
														<tr>
															<td class="text-muted fw-500 px-3 py-2">Main Contract</td>
															<td class="fw-600 px-3 py-1" v-if="singleRecurring && singleRecurring.web_url">
																{{ singleRecurring.main_contact }}
															</td>
															<em v-else class="text-muted px-3 py-1">no main contract</em>
														</tr>
														<tr>
															<td class="text-muted fw-500 px-3 py-2">Offered Month</td>
															<td class="fw-600 px-3 py-1" v-if="singleRecurring && singleRecurring.offered_month">
																{{ singleRecurring.offered_month }} Months
															</td>
															<em v-else class="text-muted px-3 py-1">no offered month</em>
														</tr>
													</table>
												</div>
											</div>
										</v-col>
									</div>
									<div class="flaot-wrapper">
										<v-col md="12" lg="6">
											<div class="detail-card bg-white">
												<div class="card--header d-flex align-items-center pa-2 border-bottom">
													<div class="font-level-3-bold">Timeframe & Sales Details</div>
													<v-spacer></v-spacer>
												</div>
												<div class="card--body d-flex align-items-center pa-2">
													<table class="w-100">
														<tr>
															<td class="text-muted fw-500 px-3 py-2">Contract Start Date</td>
															<td
																class="fw-600 px-3 py-1"
																v-if="singleRecurring && singleRecurring.contact_start_date"
															>
																{{ formatDate(singleRecurring.contact_start_date) }}
															</td>
															<em v-else class="text-muted px-3 py-1">no contract start date</em>
														</tr>
														<tr>
															<td class="text-muted fw-500 px-3 py-2">Contract End Date</td>
															<td
																class="fw-600 px-3 py-1"
																v-if="singleRecurring && singleRecurring.contact_end_date"
															>
																{{ formatDate(singleRecurring.contact_end_date) }}
															</td>
															<em v-else class="text-muted px-3 py-1">no contract end date</em>
														</tr>
														<tr>
															<td class="text-muted fw-500 px-3 py-2">Sales Date</td>
															<td class="fw-600 px-3 py-1" v-if="singleRecurring && singleRecurring.sales_date">
																{{ formatDate(singleRecurring.sales_date) }}
															</td>
															<em v-else class="text-muted px-3 py-1">no sales date</em>
														</tr>
														<tr>
															<td class="text-muted fw-500 px-3 py-2">Invoice Date</td>
															<td class="fw-600 px-3 py-1" v-if="singleRecurring && singleRecurring.invoice_date">
																{{ formatDate(singleRecurring.invoice_date) }}
															</td>
															<em v-else class="text-muted px-3 py-1">no invoice date</em>
														</tr>
														<tr>
															<td class="text-muted fw-500 px-3 py-2">Invoice Number</td>
															<td
																class="fw-600 px-3 py-1"
																v-if="singleRecurring && singleRecurring.invoice_number"
															>
																{{ singleRecurring.invoice_number }}
															</td>
															<em v-else class="text-muted px-3 py-1">no invoice number</em>
														</tr>
														<!-- <tr>
															<td class="text-muted fw-500 px-3 py-2">Payment Terms</td>
															<td class="fw-600 px-3 py-1">
																<template v-if="singleRecurring && singleRecurring.payment_terms == 'yearly' ">
																	<v-chip label color="cyan white--text">Yearly</v-chip>
																</template>
																<template v-else-if="singleRecurring && singleRecurring.payment_terms == 'half_yearly' ">
																	<v-chip label color="cyan white--text">Half Yearly</v-chip>
																</template>
																<template v-else-if="singleRecurring && singleRecurring.payment_terms == 'monthly' ">
																	<v-chip label color="cyan white--text">Monthly</v-chip>
																</template>
																<template v-else-if="singleRecurring && singleRecurring.payment_terms == 'quarterly' ">
																	<v-chip label color="cyan white--text">Quarterly</v-chip>
																</template>
																
															</td>
														</tr> -->
													</table>
												</div>
											</div>
										</v-col>
										<v-col md="12" lg="6">
											<div class="detail-card bg-white">
												<div class="card--header d-flex align-items-center pa-2 border-bottom">
													<div class="font-level-3-bold">Sales Person Details</div>
													<v-spacer></v-spacer>
												</div>
												<div class="card--body d-flex align-items-center pa-2">
													<table class="w-100">
														<tr>
															<td class="text-muted fw-500 px-3 py-2">First Name</td>
															<td class="fw-600 px-3 py-1" v-if="userData && userData.first_name">
																{{ userData.first_name }}
															</td>
															<em v-else class="text-muted px-3 py-1">no first name</em>
														</tr>
														<tr>
															<td class="text-muted fw-500 px-3 py-2">Last Name</td>
															<td class="fw-600 px-3 py-1" v-if="userData && userData.last_name">
																{{ userData.last_name }}
															</td>
															<em v-else class="text-muted px-3 py-1">no last name</em>
														</tr>
														<tr>
															<td class="text-muted fw-500 px-3 py-2">Email</td>
															<td class="fw-600 px-3 py-1" v-if="userData && userData.email">
																{{ userData.email }}
															</td>
															<em v-else class="text-muted px-3 py-1">no email</em>
														</tr>
														<tr>
															<td class="text-muted fw-500 px-3 py-2">Phone Number</td>
															<td class="fw-600 px-3 py-1" v-if="userData && userData.phone_number">
																{{ userData.phone_number }}
															</td>
															<em v-else class="text-muted px-3 py-1">no phone number</em>
														</tr>
														<tr>
															<td class="text-muted fw-500 px-3 py-2">Department</td>
															<td class="fw-600 px-3 py-1" v-if="userData && userData.department">
																{{ userData.department }}
															</td>
															<em v-else class="text-muted px-3 py-1">no department</em>
														</tr>
													</table>
												</div>
											</div>
										</v-col>
									</div>
								</v-tab-item>
								<v-tab-item value="invoice">
									<RecurringInvoiceTab :relatedId="recurringId"></RecurringInvoiceTab>
								</v-tab-item>
								<v-tab-item value="notes">
									<div class="white">
										<!-- <RecurringTab :relatedId="recurringId"></RecurringTab> -->
										<AllNotesTab
											:relatedId="recurringId"
											:relatedType="'recurring-notes'"
											create-url="recurring-note"
											get-url="recurring-note"
										>
										</AllNotesTab>
									</div>
								</v-tab-item>
								<v-tab-item value="ranking">
									<div class="white">
										<div>
											<v-layout class="p-4 border-bottom-light-grey min-height-57px">
												<v-flex class="font-level-3-bold my-auto">
													<span class="detail-svg-icon mr-2">
														<!--begin::Svg Icon-->
														<inline-svg :src="$assetURL('media/custom-svg/files-color.svg')" />
														<!--end::Svg Icon-->
													</span>
													Files
												</v-flex>
												<v-flex class="text-right">
													<v-btn
														v-on:click="uploadDialog"
														color="blue darken-4 text-white"
														class="ml-2"
														depressed
														tile
														><v-icon small left>mdi-playlist-plus</v-icon>Attach File</v-btn
													>
												</v-flex>
											</v-layout>
											<div class="overflow-y" style="max-height: calc(100vh - 345px)">
												<table width="100%" class="detail-table table-head-sticky">
													<thead>
														<tr>
															<th class="p-2 light-blue-bg" width="50"></th>
															<th class="p-2 light-blue-bg">Name</th>
															<th class="p-2 light-blue-bg" colspan="2"></th>
														</tr>
													</thead>
													<tbody v-if="dbFiles.length">
														<tr v-for="(row, index) in dbFiles" :key="index">
															<td class="p-2 border-top-light-grey" width="50">
																<template
																	v-if="
																		getFileExtension(row.url) == 'jpg' ||
																		getFileExtension(row.url) == 'jpeg' ||
																		getFileExtension(row.url) == 'png'
																	"
																>
																	<ImageTemplate style="max-width: 50px; width: 50px" :src="row.url"></ImageTemplate>
																</template>
																<template v-if="getFileExtension(row.url) == 'pdf'">
																	<inline-svg :src="$assetURL('media/mime/pdf.svg')" />
																</template>
																<template v-if="getFileExtension(row.url) == 'docx'">
																	<inline-svg :src="$assetURL('media/mime/doc.svg')" />
																</template>
																<template v-if="getFileExtension(row.url) == 'xls'">
																	<inline-svg :src="$assetURL('media/mime/xls.svg')" />
																</template>
															</td>
															<td class="p-2 border-top-light-grey">
																<p class="m-0 blue--text font-level-1">{{ row.name }}</p>
																<span class="text-muted font-small">{{ formatDate(row.added_at) }} </span>
															</td>
															<td class="p-2 border-top-light-grey font-level-1" width="50%">
																<v-layout>
																	<v-flex md11> </v-flex>
																	<v-flex md1> {{ getFileSize(row.size) }} KB </v-flex>
																</v-layout>
															</td>
															<td class="p-2 border-top-light-grey font-level-1" width="100">
																<v-btn
																	v-on:click="doAction(row, 'download')"
																	small
																	icon
																	depressed
																	color="blue darken-4"
																	class="mr-2"
																	><v-icon small>mdi-download</v-icon></v-btn
																>
																<v-btn
																	small
																	v-on:click="doAction(row, 'delete')"
																	icon
																	depressed
																	color="red lighten-1"
																	><v-icon small>mdi-delete</v-icon></v-btn
																>
																<!-- <v-btn small v-on:click="doAction(row, 'delete')" icon depressed color="red lighten-1"
																	><v-icon small>mdi-delete</v-icon></v-btn
																> -->
															</td>
														</tr>
													</tbody>
													<tfoot v-else>
														<tr>
															<td colspan="4">
																<p class="m-0 row-not-found text-center">
																	<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
																	Uhh... There are no file at the moment.
																</p>
															</td>
														</tr>
													</tfoot>
												</table>
											</div>
											<Dialog :dialog="deleteDialog" :dialog-width="600">
												<template v-slot:title> Delete File</template>
												<template v-slot:body>
													<v-row class="delete-dialog">
														<v-col md="2" class="py-0 text-right my-auto">
															<span class="svg-icon svg-icon-lg delete-confirm-icon">
																<!--begin::Svg Icon-->
																<inline-svg :src="$assetURL('media/custom-svg/attention-circle.svg')" />
																<!--end::Svg Icon-->
															</span>
														</v-col>
														<v-col md="10" class="py-0 my-auto">
															<p class="btx-p m-0">
																Deleting File is irreversible, Are you sure about deleting it?
															</p>
														</v-col>
													</v-row>
												</template>
												<template v-slot:action>
													<v-btn
														class="white--text"
														:loading="deleteLoading"
														:disabled="deleteLoading"
														depressed
														color="red lighten-1"
														tile
														v-on:click="deleteFile()"
													>
														Yes! Delete
													</v-btn>
													<v-btn depressed tile :disabled="deleteLoading" v-on:click="deleteDialog = false">
														No, Close
													</v-btn>
												</template>
											</Dialog>
										</div>
									</div>
								</v-tab-item>
								<v-tab-item value="file">
									<Files
										v-if="dataTab == 'file'"
										class="mx-4"
										type="recurring"
										:url="'recurring'"
										:type-id="recurringId"
									>
									</Files>
								</v-tab-item>
							</v-tabs-items>
						</div>
					</div>
				</div>
			</v-layout>
		</div>
		<add-dialog
			:document-dialog="openUploadModel"
			v-on:close="openUploadModel = false"
			v-on:success="getKpi()"
			:related-id="recurringId"
			:related-type="'recurring-kpi'"
		></add-dialog>
	</v-sheet>
</template>
<script>
import RecurringInvoiceTab from "@/view/module/components/RecurringInvoiceTab.vue";
import { toNumber, round, last } from "lodash";
import ImageTemplate from "@/view/components/Image";
//import Dialog from "@/view/components/Dialog";
//import RecurringTab from "@/view/pages/leads/components/Rcurring-Notes";
import AllNotesTab from "@/view/pages/leads/components/NotesTab3";
import Files from "@/view/components/Files";

import { GET, QUERY, PATCH } from "@/core/services/store/request.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SET_MESSAGE } from "@/core/services/store/common.module";
import ApiService from "@/core/services/api.service";
import { SET_ERROR } from "@/core/services/store/common.module";
//import TextAreaInput from "@/view/components/TextAreaInput";
import AddDialog from "@/view/pages/leads/create/AddDialog";
import { toSafeInteger } from "lodash";
import Dialog from "@/view/components/Dialog";
export default {
	name: "Recurring-details",
	data() {
		return {
			//	open_upload_dialog: false,
			files: [
				{
					file: null,
					remark: null,
				},
			],
			dataTab: "overview",
			singleRecurring: [],
			dbFiles: [],
			project_types: [],
			deleteLoading: false,
			export_valid: true,
			statusData: [],
			deleteDialog: false,
			recurringStatus: [],
			attachLoading: false,
			openUploadModel: false,
			fileIndex: -1,
			tagItems: ["Software", "SEO"],
			filter_tag: "Software",
			userItems: ["Everyone"],
			filter_user: "Everyone",
			filter_customer: "",
			fileList: [],
			userData: [],
			status: [
				{ color: "blue", name: "Raised", count: "2" },
				{ color: "green", name: "Paid", count: "1" },
				{ color: "red", name: "Unpaid", count: "3" },
				{ color: "cyan", name: "Postponed", count: "4" },
				{ color: "red", name: "Cancelled", count: "2" },
			],
		};
	},
	components: {
		//Dialog,
		//RecurringTab,
		AllNotesTab,
		RecurringInvoiceTab,
		//TextAreaInput,
		ImageTemplate,
		AddDialog,
		Files,
		Dialog,
	},
	methods: {
		routeToUpdate(id) {
			this.$router.push({
				name: "update-recurring",
				params: { id },
			});
		},
		uploadDialog() {
			this.openUploadModel = true;
		},
		/* closeDialog(){
			this.open_upload_dialog= false
		}, */
		getFileExtension(path) {
			if (path) {
				return last(path.split("."));
			}
		},
		doAction(row, param) {
			switch (param) {
				case "download":
					window.open(row.url, "_blank");
					break;
				case "delete":
					this.id = row.id;
					this.deleteDialog = true;
					break;
			}
		},
		addMore() {
			this.files.push({
				file: null,
			});
		},
		deleteFile() {
			if (!this.id) {
				return false;
			}
			this.deleteLoading = true;
			ApiService.delete(`recurring/${this.recurringId}/attachment/recurring-kpi/${this.id}`)
				.then(() => {
					this.deleteDialog = false;
					this.getKpi();
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.deleteLoading = false;
				});
		},
		submitDocument() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.documentForm);

			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.documentForm.validate()) {
				return false;
			}

			const params = new FormData();

			for (let i = 0; i < this.files.length; i++) {
				params.append(`file[${i}][file]`, this.files[i].file);
				if (this.files[i].remark) {
					params.append(`file[${i}][remark]`, this.files[i].remark);
				}
			}
			params.append("recurring", this.recurringId);
			this.attachLoading = true;

			ApiService.setHeader();
			ApiService.upload("upload-kpi", params)
				.then(() => {
					this.getKpi();
					this.openUploadModel = false;
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.attachLoading = false;
				});
		},
		updateFile(index, file) {
			if (file && file.name) {
				this.files[index].name = file.name.split(".").slice(0, -1).join(".");
				this.files[index].suffix = `.${file.name.split(".").pop()}`;
			} else {
				this.files[index].name = null;
				this.files[index].suffix = null;
			}
		},
		getFileSize(size) {
			return round(toNumber(size) / 1024, 2);
		},
		removeFile(index) {
			this.files.splice(index, 1);
			if (this.files.length < 1) {
				this.addMore();
			}
		},
		getKpi() {
			this.$store
				.dispatch(GET, {
					url: `recurring/${this.recurringId}/attachment/recurring-kpi`,
				})
				.then((data) => {
					this.dbFiles = data;
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		updateStatus(status) {
			this.$store
				.dispatch(PATCH, {
					url: `recurring-renew/${this.recurringId}/${status}`,
				})
				.then(() => {
					this.getRecurring();
					this.$store.commit(SET_MESSAGE, [{ model: true, message: "Recurring Update Successfully." }]);
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		statusFilter(value) {
			this.$store
				.dispatch(QUERY, { url: `recurring`, data: { status: value } })
				.then((data) => {
					this.statusData = data.tbody;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		getRecurring() {
			this.$store
				.dispatch(GET, {
					url: `single-recurring/${this.recurringId}`,
				})
				.then((data) => {
					this.singleRecurring = data.recurrings;
					this.project_types = data.project_types;

					this.userData = data.userData;
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		getRecurringSetting() {
			this.$store
				.dispatch(GET, {
					url: `recurring-setting`,
				})
				.then((data) => {
					this.recurringStatus = data.status;
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		tabDetails(id) {
			this.$router.push({
				params: { id },
			});
			this.recurringId = id;
			this.getRecurring();
		},
		pageTitle() {
			return "Recurring Details";
		},
	},
	mounted() {
		this.getRecurringSetting();
		this.getRecurring();
		this.statusFilter();
		this.getKpi();

		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Dashboard", disabled: false, href: "/dashboard" },
			{ text: "Recurring", disabled: false, href: "recurring" },
			{ text: "Detail", disabled: true },
		]);
	},
	beforeMount() {
		this.recurringId = toSafeInteger(this.$route.params.id);
	},
};
</script>
